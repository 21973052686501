import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { MyHttpApi, PaymentTerminalManufacturer, PaymentTerminalModel, PaymentTerminalSite, PaymentTerminalStatus, PaymentTerminalWithClientData } from 'utils/api';

interface UITerminal extends PaymentTerminalWithClientData {
  deleted: boolean;
  error: boolean;
  rowClass?: string;
}

@autoinject
export class AdminPaymentTerminalsList {
  private readonly defaults: { [key: string]: string; } = { "deleted": " " };

  private manufacturers: { [key: number]: PaymentTerminalManufacturer; } = {};
  private models: { [key: number]: PaymentTerminalModel; } = {};
  private sites: { [key: number]: PaymentTerminalSite; } = {};
  private statuses: { [key: number]: PaymentTerminalStatus; } = {};

  private terminalList: PaymentTerminalWithClientData[] = [];

  private errors = 0;

  // * Add last client seen, add last timestamp seen
  private readonly fieldList: FieldSpec[] = [
    { key: "id", header: "ID", type: "text", },
    { key: "serialNumber", header: "payment-terminal.serialNumber", type: "text", },
    { key: "integrationPartner", header: "payment-terminal.integrationPartner", type: "enum", enum: "PaymentTerminalIntegrationPartner" },
    { key: "modelId", header: "payment-terminal.model", type: "lookup", lookupData: this.models, lookupKey: "name" },
    { key: "isSpare", header: "payment-terminal.isSpare", type: "boolean", },
    { key: "siteId", header: "payment-terminal.site", type: "lookup", lookupData: this.sites, lookupKey: "name" },
    { key: "statusId", header: "payment-terminal.status", type: "lookup", lookupData: this.statuses, lookupKey: "name" },
    { key: "owner", header: "payment-terminal.owner", type: "enum", enum: "PaymentTerminalOwner" },
    { key: "clientName", header: "unit.unit", type: "text", },
    { key: "lastClientName", header: "unit.lastSeen", type: "text", },
    { key: "lastResultTime", header: "payment-terminal.lastTerminalQueueTime", type: "date", },
    { key: "terminalVersion", header: "payment-terminal.lastTerminalVersion", type: "text", },
    { key: "integrationState", header: "payment-terminal.integrationState", type: "enum", enum: "PaymentTerminalIntegrationState" },
    { key: "error", header: "common.error", type: "boolean", },
    { key: "deleteTime", header: "common.deleteTime", type: "date", },
    { key: "deleted", header: "common.deleted", type: "boolean", },
  ];

  constructor(private readonly api: MyHttpApi, private readonly router: Router) {
  }

  async activate() {
    const [paymentTerminalList, manufacturerList, modelList, siteList, statusList, ] = await Promise.all([
      this.api.paymentTerminalListWithClientData({ all: true }),
      this.api.paymentTerminalListManufacturers({ all: true }),
      this.api.paymentTerminalListModels({ all: true }),
      this.api.paymentTerminalListSites({ all: true }),
      this.api.paymentTerminalListStatuses({ all: true }),
    ]);
    this.terminalList = paymentTerminalList.map(x => {
      const item: UITerminal = {...x,
        deleted: !!x.deleteTime,
        rowClass: "",
        error: false,
      };
      if (item.clientName && item.lastClientName && item.clientName != item.lastClientName) {
        this.errors++;
        item.rowClass = "error";
        item.error = true;
      }
      return item;
  });
    this.manufacturers = MyHttpApi.toHash(manufacturerList);
    this.models = MyHttpApi.toHash(modelList);
    this.sites = MyHttpApi.toHash(siteList);
    this.statuses = MyHttpApi.toHash(statusList);
  }

  rowCall(key: string, row: PaymentTerminalWithClientData) {
    if (row.id) {
      this.router.navigateToRoute("admin/payment-terminals/edit", { id: "" + row.id });
    }
  }
}
