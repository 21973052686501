import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { InvoicingSnapshot, MyHttpApi } from 'utils/api';

interface UIInvoicingSnapshot extends InvoicingSnapshot {
  isBillingDate: boolean;
}

@autoinject
export class AdminReportUnitList {
  private snapshotList: UIInvoicingSnapshot[] = [];
  private readonly defaults: { [key: string]: any; } = { isBillingDate: "✓" };

  private readonly fieldList: FieldSpec[] = [
    { key: "id", header: "#", type: "number", },
    { key: "cutoffDate", header: "unitReport.cutoffDate", type: "date", format: "DD.MM.YYYY" },
    { key: "reportDate", header: "unitReport.reportDate", type: "date", format: "DD.MM.YYYY" },
    { key: "createTime", header: "common.timestamp", type: "date", },
    { key: "isBillingDate", header: "unitReport.changeOfMonth", type: "boolean" }
  ];

  constructor(private readonly api: MyHttpApi, private readonly router: Router, private readonly i18n: I18N) {
  }

  async activate() {
    await this.refresh();
  }

  async refresh() {
    let snapshotList = await this.api.reportListUnit();
    this.snapshotList = snapshotList.map(x => {
      const d = new Date(x.reportDate);
      d.setDate(d.getDate() + 1);
      const isBillingDate =  d.getDate() == 1;
      return {
        ...x,
        isBillingDate,
      };
    });
  }

  @computedFrom("i18n.i18next.language")
  get summaryUrl() {
    return this.api.reportUnitSummaryExcelUrl({ lang: this.i18n.i18next.language });
  }

  rowCall(key: string, row: InvoicingSnapshot) {
    if (row.id) {
      this.router.navigateToRoute("admin/report/unit-show", { id: "" + row.id });
    }
  }
}
