import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { InvoicingData, InvoicingDataRow, MyHttpApi } from 'utils/api';

interface UIInvoicingDataRow extends InvoicingDataRow {
  rowClass?: string;
}

@autoinject
export class AdminReportUnitShow {
  private invoicingData?: InvoicingData;
  private invoicingRows: UIInvoicingDataRow[] = [];
  private id = 0;
  private readonly dataFieldList: FieldSpec[] = [
    { cssClass: "not-clickable", key: "name", header: "common.name", type: "text" },
    { cssClass: "not-clickable", key: "modulesString", header: "client.modules", type: "text" },
    { cssClass: "not-clickable", key: "belServer", header: "client.belServer", type: "text" },
    { cssClass: "not-clickable", key: "bookkeepingStrategy", header: "client-sale.integration", type: "text" },
    { cssClass: "not-clickable", key: "branch", header: "client.version", type: "text" },
    { cssClass: "not-clickable", key: "terminals", header: "unitReport.terminals", type: "number" },
    { cssClass: "not-clickable", key: "billCount", header: "unitReport.billsLast28d", type: "number" },
    { cssClass: "not-clickable", key: "firstBill", header: "unitReport.firstBill", type: "date", format: "DD.MM.YYYY" },
    { cssClass: "not-clickable", key: "airpay1m", header: "unitReport.airpay", type: "number", },
    { cssClass: "not-clickable", key: "isNew", header: "unitReport.isNew", type: "boolean" },
    { cssClass: "not-clickable", key: "active", header: "unitReport.active", type: "boolean" },
    { cssClass: "not-clickable", key: "hasHealth", header: "unitReport.hasHealth", type: "boolean" },
  ];

  private readonly serverFieldList: FieldSpec[] = [
    { cssClass: "not-clickable", key: "serverLocation", header: "servers.location", type: "text" },
    { cssClass: "not-clickable", key: "serverType", header: "servers.type", type: "text" },
    { cssClass: "not-clickable", key: "count", header: "common.amount", type: "number" },
  ];

  constructor(private readonly router: Router, private readonly api: MyHttpApi, private readonly i18n: I18N) {
  }

  async activate(params: { id: string; }) {
    this.id = parseInt(params.id);
      this.invoicingData = await this.api.reportUnitDataById({ id: this.id });
      if (this.invoicingData) {
        this.invoicingRows = this.invoicingData.rows.map(r => {
          let row = {...r, rowClass: "" };
          if (!row.hasHealth) {
            row.rowClass = "health-missing";
          } else if (!row.active) {
            row.rowClass = "passive";
          }
          return row;
        });
      }
    }
    @computedFrom("id", "i18n.i18next.language")
    get excelUrl() {
      return this.api.reportUnitDataByIdExcelUrl({id: this.id, lang: this.i18n.getLocale()});
    }
  }
