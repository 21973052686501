// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.important {
  background-color: orange;
}
.passive {
  color: #AAAAAA !important;
}
.health-missing {
  background-color: red !important;
  color: white;
}
`, "",{"version":3,"sources":["webpack://./src/admin/report/unit-show.less"],"names":[],"mappings":"AAAA;EACE,wBAAA;AACF;AACA;EACE,yBAAA;AACF;AACA;EACE,gCAAA;EACA,YAAA;AACF","sourcesContent":[".important {\n  background-color: orange;\n}\n.passive {\n  color: #AAAAAA !important;\n}\n.health-missing {\n  background-color: red !important;\n  color: white;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
